<template>
  <div class="fixed-bottom">
    <div class="fixed-flex">
      <van-popover v-model="showOffice" trigger="click" :actions="actionsOffice" @select="onSelect" placement="top">
      <template #reference>
          <van-button style="border:0px" :icon="tabIcon">公文</van-button>
      </template>
      </van-popover>
      <van-popover v-model="showSchedule" trigger="click" :actions="actionsSchedule" @select="onSelect" placement="top">
        <template #reference>
          <van-button style="border:0px" :icon="tabIcon">日程</van-button>
        </template>
      </van-popover>
      <van-popover v-model="showWorks" trigger="click" :actions="actionsWork" @select="onSelect" placement="top">
        <template #reference>
          <van-button style="border:0px" :icon="tabIcon">功能</van-button>
        </template>
      </van-popover>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FooterTabbar',
  data () {
    return {
      active:'approval',
      showOffice:false,
      showSchedule:false,
      showWorks:false,
      tabIcon:require('@/assets/tabIcon.jpg'),
      // personIcon:personIcon,
      actionsOffice:[
        {text:'待办文件',url:'/todolist'},
        {text:'已办文件',url:'/donelist'},
        {text:'关注文件',url:'/concerns'},
        {text:'办件统计',url:'/dashboard'}
      ],
      actionsSchedule:[
        {text:'领导工作安排',url:'/leaderwork'},
        {text:'会议室安排',url:'/meeting'}
      ],
      actionsWork:[
        {text:'已收提醒',url:'/noticelist?title=已收提醒&type=isRead'},
        {text:'已发提醒',url:'/noticelist?title=已发提醒&type=unRead'},
        {text:'账号绑定',url:'/account/binding'},
        {text:'重置密码',url:'/resetpwd'}
      ]
    }
  },
  mounted(){
    this.$store.dispatch('delAllCachedViews');
  },
  methods:{
    handleClick(){
      
    },
    onSelect(selection){
      // 跳转路由
      this.$router.push(selection.url);
    },
  }
}
</script>
<style lang="scss" scoped>
  .fixed-bottom{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
  }
  .fixed-flex{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  ::v-deep .van-button__icon{
    font-size: 0.10rem;
  }
  ::v-deep .van-popover__arrow{
    display: none;
  }
</style>
